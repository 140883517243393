export const examList = [
    { value: "1", lable: "General Studies 1 (GS1)" },
    { value: "2", lable: "General Studies 2 (GS2)" },
    { value: "3", lable: "General Studies 3 (GS3)" },
    { value: "4", lable: "General Studies 4 (GS4)" },
]

// const roleData = JSON.parse(localStorage.getItem('userDetails'));
// export var roleType = "";
// export var roleID = "";
// export const expiredTime = roleData?.expiry;

// console.log(roleData,"roleData-filter-data")
// switch (roleData?.loginData?.role) {
//     case "teacher":
//         roleType = "teacher";
//         roleID = roleData?.loginData?.id;
//         break;
//     case "Student":
//         roleType = "student";
//         roleID = roleData?.loginData?.id;
//         break;
//     case "admin":
//         roleType = "admin";
//         roleID = roleData?.loginData?.id;
//         break;

//     case "user":
//         roleType = "user";
//         roleID = roleData?.loginData?.id;
//         break;
//     default:
//         roleType = "undefined"
//         roleID = "no id";
// }