import React from 'react'
import { Grid } from '@mui/material'


function AdminSetting() {
  return (
    <div style={{ padding: "40px 20px" }}>
      <Grid container spacing={2}>
        <Grid item sx={12} md={6} lg={6}>
          <div><h5 className='page-name'>Setting</h5></div>
        </Grid>
      </Grid>
    </div>

  )
}

export default AdminSetting