import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoggedin: false,
    loginData: ""
}

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setIsLoggedin: (state, action) => {
            state.isLoggedin = action.payload;
        },
        setLoginData: (state, action) => {
            state.loginData = action.payload;
        },
    }
})


const { setIsLoggedin, setLoginData } = loginSlice.actions

export default loginSlice.reducer;

export { setIsLoggedin, setLoginData };

