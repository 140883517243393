import React, { useState } from 'react'
import { toast } from 'react-toastify';
import axios from 'axios';
import * as Yup from "yup";
import { Button, CircularProgress, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { ErrorMessage, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BASE_URL } from '../../../api/client';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";


const addStudentSchema = Yup.object().shape({
    firstName: Yup.string().trim().required("* Please enter first name"),
    lastName: Yup.string().trim().required("* Please enter last name"),
    userName: Yup.string().trim().required("* Please enter user name"),
    password: Yup.string().trim().required("* Please enter password"),
});

function AddTeacher() {
    const navigate = useNavigate();
    const addStudentInitValues = {
        firstName: "",
        lastName: "",
        userName: "",
        password: "",
    };

    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false);

    const handleTeacherSubmit = (values) => {
        let payload = {
            real_name: values.firstName + values.lastName,
            username: values.userName,
            password: values.password,
            role: "teacher",
            validity: true,
            otherdata: []
        }
        console.log("add-teacher-values", values, "payload", payload);
        setLoading(true);
        axios
            .post(`${BASE_URL}/createteacher/`, payload)
            .then((res) => {
                const respo = res?.status === 200;
                if (respo) {
                    console.log("res?.data", res?.data)
                    setLoading(false);
                    toast.success("Teacher added successfully");
                    navigate("/admin/teachers");
                }
                else {
                    setLoading(false);
                    toast.error("Teacher added failed");
                }
            })
            .catch((err) => {
                setLoading(false);
                toast.error("Teacher added failed");
                console.log('error in axios', err);
            });
    }

    const handleBackToTeacher = () => {
        navigate("/admin/teachers");
    }

    return (
        <Grid container>
            <Grid item md={12} lg={12} sm={12}>
                <div style={{ margin: "3%" }}>
                    <div className='add-student-back-btn-div'>
                        <Button variant="outlined" onClick={handleBackToTeacher} className='student-back-btn'><ArrowBackIcon /> Back</Button>
                    </div>
                </div>
                <div className="add-student-pratent-div">
                    <div className='custom-box-style'>
                        <Formik
                            validationSchema={addStudentSchema}
                            initialValues={addStudentInitValues}
                            validateOnBlur={false}
                            validateOnChange={false}
                            onSubmit={(values) => handleTeacherSubmit(values)}
                        >
                            {(formik) => {
                                const {
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    values,
                                } = formik;
                                return (
                                    <form onSubmit={handleSubmit} autoComplete="off">
                                        <div className='field-warps'>
                                            <Typography className='login-field-lables'>First Name</Typography>
                                            <TextField
                                                id="firstName"
                                                name="firstName"
                                                placeholder="Enter first name"
                                                className="login-text-fields"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.firstName}
                                            />
                                            <ErrorMessage
                                                name="firstName"
                                                component="span"
                                                className="error"
                                            />
                                        </div>
                                        <div className='field-warps'>
                                            <Typography className='login-field-lables'>Last Name</Typography>
                                            <TextField
                                                id="lastName"
                                                name="lastName"
                                                placeholder="Enter last name"
                                                className="login-text-fields"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.lastName}
                                            />
                                            <ErrorMessage
                                                name="lastName"
                                                component="span"
                                                className="error"
                                            />
                                        </div>
                                        <div className='field-warps'>
                                            <Typography className='login-field-lables'>User Name</Typography>
                                            <TextField
                                                id="userName"
                                                name="userName"
                                                placeholder="Enter user name"
                                                className="login-text-fields"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.userName}
                                            />
                                            <ErrorMessage
                                                name="userName"
                                                component="span"
                                                className="error"
                                            />
                                        </div>
                                        <div className='field-warps'>
                                            <Typography className='login-field-lables'>Password</Typography>
                                            <TextField
                                                id="password"
                                                name="password"
                                                placeholder="Enter password"
                                                className="login-text-fields"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type={showPassword ? "text" : "password"}
                                                value={values.password}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            sx={{ cursor: "pointer" }}
                                                            onClick={() => setShowPassword(!showPassword)}
                                                        >
                                                            {showPassword ? (
                                                                <VisibilityIcon />
                                                            ) : (
                                                                <VisibilityOffIcon />
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <ErrorMessage
                                                name="password"
                                                component="span"
                                                className="error"
                                            />
                                        </div>
                                        <div >
                                            <Button className='login-button' type="submit">
                                                {loading ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : " Add teacher"}
                                            </Button>
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default AddTeacher