import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SwipeableViews from "react-swipeable-views";
import LoginSlied1 from "../../assets/images/login-slide1.png";
import LoginSlied2 from "../../assets/images/login-slide2.png";
import LoginSlied3 from "../../assets/images/login-slide3.png";


const images = [
  {
    title: "Unlock Effortless Grading",
    content: "Experience the future of grading with our AI-powered system. Say goodbye to hours of manual grading and hello to faster, more accurate results. Your students' essays just got a whole lot easier to manage.",
    imgPath: LoginSlied1,
  },
  {
    title: "Unlock Effortless Grading",
    content: "Experience the future of grading with our AI-powered system. Say goodbye to hours of manual grading and hello to faster, more accurate results. Your students' essays just got a whole lot easier to manage.",
    imgPath: LoginSlied2
  },
  {
    title: "Unlock Effortless Grading",
    content: "Experience the future of grading with our AI-powered system. Say goodbye to hours of manual grading and hello to faster, more accurate results. Your students' essays just got a whole lot easier to manage.",
    imgPath: LoginSlied3
  },
];

function SwipeableTextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleDotClick = (index) => {
    setActiveStep(index);
  };

  return (
    <div className="carousel-over-all-div-warps">
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={index} className="login-slide-img">
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: 254,
                  width: "90%",
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </SwipeableViews>
      <div
        elevation={0}
        style={{backgroundColor:"none"}}
      >
        <div className="login-slide-text">
          <h5 className="carousel-title">{images[activeStep].title}</h5>
          <p className="carousel-content">{images[activeStep].content}</p>
        </div>
      </div>
      <div style={{ textAlign: "center", marginTop: "10px" }}>
        {images.map((_, index) => (
          <span
            key={index}
            onClick={() => handleDotClick(index)}
            style={{
              cursor: "pointer",
              display: "inline-block",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: activeStep === index ? "#333" : "#ccc",
              margin: "0 5px",
            }}
          />
        ))}
      </div>
    </Box>
    </div>
  );
}

export default SwipeableTextMobileStepper;
