import React, { useState } from 'react'
import axios from 'axios';
import { Button, CircularProgress, Grid, InputLabel, Switch, TextField } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BASE_URL } from '../../../api/client';
import { toast } from 'react-toastify';



function ViewStudentInfo() {

    const navigate = useNavigate();
    const { state } = useLocation();
    const { data } = state;
    console.log("state", state, data)
    const [loading, setLoading] = useState(false);
    const [getStudent, setGetStudent] = useState(data);
    const [statusMessage, setStatusMessage] = useState();
    const [statusLable, setStatusLable] = useState();
    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

    const handleStatusChange = (e) => {
        setGetStudent({ "validity": e.target.checked })
        setStatusLable(e.target.checked ? "activate" : "deactivate");
        setStatusMessage(e.target.checked ? "activated" : "deactivated");
        setOpenDeleteConfirmDialog(true);
    }

    const handleBackToAdminStudent = () => {
        navigate("/admin/students");
    };

    const handleDeleteConfirmClose = () => {
        setOpenDeleteConfirmDialog(false);
        setGetStudent({ "validity": data?.validity })
    };

    const handleStudentDelete = () => {
        let payload = {
            id: data.id,
            validity: getStudent.validity
        }
        setOpenDeleteConfirmDialog(false);
        setLoading(true);
        console.log("payload", payload, "getStudent", getStudent)
        axios
            .post(`${BASE_URL}/modifystudent/`, payload)
            .then((res) => {
                const respo = res?.status === 200;
                if (respo) {
                    console.log("res?.data", res?.data)
                    setLoading(false);
                    toast.success(`Student ${statusMessage} successfully`);
                    navigate("/admin/students");
                }
                else {
                    setLoading(false);
                    toast.error(`Student ${statusMessage} failed`);
                    navigate("/admin/students");
                }
            })
            .catch((err) => {
                setLoading(false);
                toast.error(`Student ${statusMessage} failed`);
                navigate("/admin/students");
                console.log('error in axios', err);
            });
    }

    return (
        <>
            <Dialog
                open={openDeleteConfirmDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to {statusLable}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='agree-disagree-btn-div'>
                    <Button variant="contained" disabled={loading} onClick={handleDeleteConfirmClose} className="disagree-btn">Cancel</Button>
                    <Button variant="contained" onClick={handleStudentDelete} style={{ textTransform: "capitalize" }}>
                        {loading ? (
                            <CircularProgress size={24} sx={{ color: "#fff" }} />
                        ) : (
                            statusLable
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
            <div style={{ padding: "40px 20px" }}>
                <div className='teacherview-back-btn-parent-div' style={{ justifyContent: "space-between" }}>
                    <div className='studnetView-back-btn-div'><Button variant="outlined" onClick={handleBackToAdminStudent} className='student-back-btn'><ArrowBackIcon /> Back</Button></div>
                    <div><h5 className='page-name'>Student Name : {data?.first_name} {data?.last_name}</h5></div>
                </div>
                <Grid container spacing={3}>
                    <Grid item sx={12} md={6} lg={6}>
                        <InputLabel className='login-field-lables'>Roll Number</InputLabel>
                        <TextField
                            id="rollNumber"
                            name="rollNumber"
                            placeholder="Enter roll number"
                            className="login-text-fields"
                            disabled={true}
                            value={getStudent?.roll_number}
                        />
                    </Grid>
                    <Grid item sx={12} md={6} lg={6}>
                        <InputLabel className='login-field-lables'>First Name</InputLabel>
                        <TextField
                            id="firstName"
                            name="firstName"
                            placeholder="Enter first name"
                            className="login-text-fields"
                            disabled={true}
                            value={getStudent?.first_name}
                        />
                    </Grid>
                    <Grid item sx={12} md={6} lg={6}>
                        <InputLabel className='login-field-lables'>Last Name</InputLabel>
                        <TextField
                            id="lastName"
                            name="lastName"
                            placeholder="Enter last name"
                            className="login-text-fields"
                            disabled={true}
                            value={getStudent?.last_name}
                        />
                    </Grid>
                    <Grid item sx={12} md={6} lg={6}>
                        <InputLabel className='login-field-lables'>Status</InputLabel>
                        <div style={{ position: 'relative', display: 'flex', width: '100%' }}>
                            <p>Deactive</p>
                            <div style={{ position: 'relative', top: '8px' }}>
                                <Switch id='report-toggle-switch' name="validity" onChange={handleStatusChange} checked={getStudent.validity} />
                            </div>
                            <p>Active</p>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default ViewStudentInfo