import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CircularProgress, TablePagination } from '@mui/material';
import { Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { BASE_URL } from '../../api/client';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function ExistingTemplate(props) {
    const { hanldeApplyfunction } = props
    const [templateData, setTemplateData] = useState();
    const [viewTableData, setViewTableData] = useState([])
    const [viewTemplateName, setViewTemplateName] = useState()

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [viewTablePage, setViewTablePage] = useState(0);
    const [viewTableRowsPerPage, setViewTableRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(false)
    const roleType = JSON.parse(localStorage.getItem('userDetails'))


    useEffect(() => {
        setLoading(true);
        let payload = {
            teacher: roleType?.loginData?.id
        }
        axios
            .post(`${BASE_URL}/seetemplates/`, payload)
            .then((res) => {
                const respo = res?.status === 200;
                if (respo) {
                    setTemplateData(res?.data?.templates)
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log('error in axios', err);
            });
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleViewChangePage = (event, newPage) => {
        setViewTablePage(newPage);
    };

    const handleViewChangeRowsPerPage = (event) => {
        setViewTableRowsPerPage(parseInt(event.target.value, 10));
        setViewTablePage(0);
    };

    const handleTemplateView = (e, data, name) => {
        setViewTableData(JSON.parse(data))
        setViewTemplateName(name)
    }

    return (
        <div className='tab-content'>
            {loading ?
                <div className='loder-div-styles'>
                    <CircularProgress size={50} />
                </div>
                :
                templateData && templateData.length > 0 ?
                    (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell style={{ width: "25%" }}>Name</StyledTableCell>
                                        <StyledTableCell style={{ width: "20%" }}>Handwritten</StyledTableCell>
                                        <StyledTableCell style={{ width: "25%" }}>Created at</StyledTableCell>
                                        <StyledTableCell style={{ width: "20%" }}>Created By</StyledTableCell>
                                        <StyledTableCell style={{ width: "10%" }}>Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? templateData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : templateData
                                    )?.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row">{row?.name}</StyledTableCell>
                                            <StyledTableCell>{row?.isHandwriting === 1 ? "true" : row?.isHandwriting === 0 ? "false" : JSON.stringify(row?.isHandwriting)}</StyledTableCell>
                                            <StyledTableCell>{row?.createdAt}</StyledTableCell>
                                            <StyledTableCell>{row?.createdBy}</StyledTableCell>
                                            <StyledTableCell><VisibilityIcon style={{ cursor: "pointer" }} onClick={(e) => handleTemplateView(e, row?.data, row?.name)} /></StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {templateData.length > 5 &&
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={templateData?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />}
                        </TableContainer>
                    ) : <div className='no-existing-template'>no existing template</div>}

            {viewTableData && viewTableData.length > 0 && (
                <div className='tab-content'>
                    <h5 className='view-templateName'>Template name : {viewTemplateName}</h5>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell className='template-table-head-cell1'>QUESTIONS</StyledTableCell>
                                    <StyledTableCell className='template-table-head-cell2'>MAX MARKS</StyledTableCell>
                                    <StyledTableCell className='template-table-head-cell2'>MAX WORDS</StyledTableCell>
                                    <StyledTableCell className='template-table-head-cell3'>MODEL SOLUTION</StyledTableCell>
                                    <StyledTableCell className='template-table-head-cell3'>SPECIAL INSTRUCION</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(viewTableRowsPerPage > 0
                                    ? viewTableData?.slice(viewTablePage * viewTableRowsPerPage, viewTablePage * viewTableRowsPerPage + viewTableRowsPerPage)
                                    : viewTableData)?.map((row, index) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell>{row.question}</StyledTableCell>
                                                <StyledTableCell>{row.max_marks}</StyledTableCell>
                                                <StyledTableCell>{row.max_words}</StyledTableCell>
                                                <StyledTableCell>{row.model_solution}</StyledTableCell>
                                                <StyledTableCell>{row.special_instructions}</StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>
                        {viewTableData.length > 5 &&
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={viewTableData?.length}
                                rowsPerPage={viewTableRowsPerPage}
                                page={viewTablePage}
                                onPageChange={handleViewChangePage}
                                onRowsPerPageChange={handleViewChangeRowsPerPage}
                            />}
                    </TableContainer>
                    <div className='apply-template-btn'>
                        <Button variant="contained" onClick={(e) => hanldeApplyfunction(e, viewTableData)} style={{ textTransform: "capitalize" }}>Detection Questions with Template</Button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ExistingTemplate
