import React, { useState, useEffect } from 'react'
import axios from 'axios';
import "./style.css"
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, CircularProgress, Grid, InputAdornment, TextField } from '@mui/material';
import { TablePagination } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BASE_URL } from '../../api/client';
import { examList } from "../grade/filter";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { toast } from 'react-toastify';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: 12
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


function ViewStudentProfile() {

    const navigate = useNavigate();
    const { state } = useLocation();
    const { data } = state;

    const [viewProfile, setViewProfile] = useState([]);
    const [viewPage, setViewPage] = useState(0);
    const [viewRowsPerPage, setViewRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const roleType = JSON.parse(localStorage.getItem('userDetails'))
    useEffect(() => {
        setLoading(true);
        axios
            .get(`${BASE_URL}/getessays/?teacherid=${roleType?.loginData?.id}&studentid=${data?.id}`)
            .then((res) => {
                const respo = res?.status === 200;
                if (respo) {
                    setViewProfile(res?.data?.essays);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log('error in axios', err);
            });
    }, []);

    const handleChangeViewPage = (event, newPage) => {
        setViewPage(newPage);
    };

    const handleChangeViewRowsPerPage = (event) => {
        setViewRowsPerPage(parseInt(event.target.value, 10));
        setViewPage(0);
    };

    const handleDeleteConfirmOpen = () => {
        setOpenDeleteConfirmDialog(true);
    }

    const handleBackToStudnet = () => {
        navigate("/students");
    };

    const handleDeleteConfirmClose = () => {
        setOpenDeleteConfirmDialog(false);
    };

    function compareArrays(essayValue) {
        let matchingItem = examList?.find(item => item.value === essayValue);
        return matchingItem ? matchingItem?.lable : "No topic";
    }

    const handleStudentDelete = () => {
        let payload = {
            student_ids: [data?.id]
        }
        setLoading(true);
        axios
            .post(`${BASE_URL}/delstudents/`, payload)
            .then((res) => {
                const respo = res?.status === 200;
                if (respo) {
                    setLoading(false);
                    toast.success("Student deleted successfully");
                    navigate("/students");
                }
                else {
                    setLoading(false);
                    toast.error("Student delete failed");
                    handleDeleteConfirmClose();
                }
            })
            .catch((err) => {
                handleDeleteConfirmClose();
                setLoading(false);
                toast.error("Student delete failed");
                console.log('error in axios', err);
            });
    }

    const handleSearch = (event) => {
        setSearchQuery(event.target.value.trim());
        setViewPage(0);
    };

    const viewProfileSearchData = searchQuery !== '' ?
        viewProfile?.filter((row) =>
            compareArrays(row?.exam).toLowerCase().includes(searchQuery.toLowerCase())
        ) : viewProfile;

    return (
        <>
            <Dialog
                open={openDeleteConfirmDialog}
                onClose={handleDeleteConfirmClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='agree-disagree-btn-div'>
                    <Button variant="contained" onClick={handleDeleteConfirmClose} className="disagree-btn">Cancel</Button>
                    <Button variant="contained" onClick={handleStudentDelete} style={{ textTransform: "capitalize" }} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <div style={{ padding: "40px 20px" }}>
                <Grid container spacing={2}>
                    <Grid item sx={12} md={6} lg={6}>
                        <div className='studentview-back-btn-parent-div'>
                            <div className='studnetView-back-btn-div'><Button variant="outlined" onClick={handleBackToStudnet} className='student-back-btn'><ArrowBackIcon /> Back</Button></div>
                            <div><h5 className='page-name'>{data?.first_name} {data?.last_name}</h5></div>
                        </div>
                    </Grid>
                    <Grid item sx={12} md={4} lg={4}>
                        <div className="studnet-search-div">
                            <TextField
                                className='search-field'
                                name="regNumber"
                                placeholder={"Search"}
                                type={"text"}
                                onChange={(e) => handleSearch(e)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="start"
                                            sx={{ cursor: "pointer", marginLeft: "0px" }}
                                        >
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item sx={12} md={2} lg={2}>
                        <div>
                            <Button variant="outlined" onClick={handleDeleteConfirmOpen} className='delete-btn'><DeleteOutlineOutlinedIcon />Delete Profile</Button>
                        </div>
                    </Grid>
                </Grid>
                {loading ?
                    <div className='loder-div-styles'>
                        <CircularProgress size={50} />
                    </div>
                    :
                    <>
                        <TableContainer component={Paper} style={{ marginTop: "25px" }}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="center" className='view-profile-TH-cell1'>S. NO.</StyledTableCell>
                                        <StyledTableCell className='view-profile-TH-cell2'>TOPIC</StyledTableCell>
                                        <StyledTableCell className='view-profile-TH-cell3'>FILE</StyledTableCell>
                                        <StyledTableCell className='view-profile-TH-cell1'>FORMAT</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {viewProfileSearchData && viewProfileSearchData.length > 0 ?
                                        viewProfileSearchData?.slice(viewPage * viewRowsPerPage, viewPage * viewRowsPerPage + viewRowsPerPage)
                                            ?.map((row, index) => (
                                                <StyledTableRow key={viewPage * viewRowsPerPage + index}>
                                                    <StyledTableCell align="center">{viewPage * viewRowsPerPage + index + 1}</StyledTableCell>
                                                    <StyledTableCell>
                                                        {compareArrays(row?.exam)}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <a className='view-profile-tag' target='_blank' rel="noreferrer" href={row?.file} download={row?.file}>{row?.file}</a>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center"><PictureAsPdfIcon /></StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        :
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={4} align="center">No Data Found</StyledTableCell>
                                        </StyledTableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {viewProfileSearchData.length > 10 &&
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 50]}
                                component="div"
                                count={viewProfileSearchData?.length}
                                rowsPerPage={viewRowsPerPage}
                                page={viewPage}
                                onPageChange={handleChangeViewPage}
                                onRowsPerPageChange={handleChangeViewRowsPerPage}
                            />}
                    </>}
            </div>
        </>
    )
}

export default ViewStudentProfile