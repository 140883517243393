import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./style.css"
import moment from 'moment';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useNavigate } from "react-router-dom";
import { CircularProgress, TablePagination } from '@mui/material';
import { Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { BASE_URL } from '../../api/client';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: 12
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function Templates() {
    const navigate = useNavigate();
    const [templateData, setTemplateData] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
    const [deleteID, setDeleteID] = useState()
    const roleType = JSON.parse(localStorage.getItem('userDetails'))

    useEffect(() => {
        const currentDate = moment(new Date()).toISOString();
        if (currentDate > roleType?.expiry) {
            localStorage.removeItem("userDetails");
            toast.error("session expired");
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
    }, []);

    const seeTemplateApi = () => {
        setLoading(true);
        let payload = {
            teacher: roleType?.loginData?.id
        }
        axios
            .post(`${BASE_URL}/seetemplates/`, payload)
            .then((res) => {
                const respo = res?.status === 200;
                if (respo) {
                    setTemplateData(res?.data?.templates)
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log('error in axios', err);
            });
    }

    useEffect(() => {
        seeTemplateApi();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleGoToEditTemplate = (e, row) => {
        navigate("/editTemplate", { state: { data: row } });
    }

    const handleDeleteConfirmOpen = (e, id) => {
        setOpenDeleteConfirmDialog(true);
        setDeleteID(id)
    }

    const handleDeleteConfirmClose = () => {
        setOpenDeleteConfirmDialog(false);
    };

    const handleTemplateDelete = () => {
        setDeleteLoading(true);
        let payload = {
            template_ids: [deleteID]
        }
        axios
            .post(`${BASE_URL}/deltemplates/`, payload)
            .then((res) => {
                const respo = res?.status === 200;
                if (respo) {
                    setOpenDeleteConfirmDialog(false);
                    toast.success("Template deleted successfully");
                    setDeleteLoading(false);
                    seeTemplateApi();
                }
                else {
                    toast.error("Template deleted failed");
                    setDeleteLoading(false);
                    setOpenDeleteConfirmDialog(false);
                }
            })
            .catch((err) => {
                setDeleteLoading(false);
                setOpenDeleteConfirmDialog(false);
                toast.error("Template deleted failed");
                console.log('error in axios', err);
            });

    }

    return (
        <div style={{ padding: "40px 20px" }}>
            <div><h5 className='page-name'>Templates</h5></div>
            {loading ?
                <div className='loder-div-styles'>
                    <CircularProgress size={50} />
                </div>
                :
                templateData && templateData.length > 0 ?
                    (
                        <TableContainer component={Paper} style={{ marginTop: "25px" }}>
                            <Table sx={{ minWidth: 650 }} aria-label="customized table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell className='template-TH-cell1'>Name</StyledTableCell>
                                        <StyledTableCell className='template-TH-cell2'>Handwritten</StyledTableCell>
                                        <StyledTableCell className='template-TH-cell1'>Created at</StyledTableCell>
                                        <StyledTableCell className='template-TH-cell1'>Created By</StyledTableCell>
                                        <StyledTableCell align="center" className='template-TH-cell3'>Action</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? templateData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : templateData
                                    )?.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row">{row?.name}</StyledTableCell>
                                            <StyledTableCell>{row?.isHandwriting === 1 ? "true" : row?.isHandwriting === 0 ? "false" : JSON.stringify(row?.isHandwriting)}</StyledTableCell>
                                            <StyledTableCell>{row?.createdAt}</StyledTableCell>
                                            <StyledTableCell>{row?.createdBy}</StyledTableCell>
                                            <StyledTableCell align='center'>
                                                <div className='template-action-div'>
                                                    <VisibilityIcon className='icon-styles' onClick={(e) => handleGoToEditTemplate(e, row)} />
                                                    <DeleteIcon className='icon-styles' onClick={(e) => handleDeleteConfirmOpen(e, row?.id)} />
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {templateData.length > 10 &&
                                <TablePagination
                                    rowsPerPageOptions={[10, 20, 25]}
                                    component="div"
                                    count={templateData?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />}
                        </TableContainer>
                    ) : <div className='no-existing-template'>no existing template</div>}
            <Dialog
                open={openDeleteConfirmDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='agree-disagree-btn-div'>
                    <Button variant="contained" disabled={deleteLoading} onClick={handleDeleteConfirmClose} className="disagree-btn">Cancel</Button>
                    <Button variant="contained" onClick={handleTemplateDelete} style={{ textTransform: "capitalize" }}>
                        {deleteLoading ? (
                            <CircularProgress size={24} sx={{ color: "#fff" }} />
                        ) : (
                            "Delete"
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

export default Templates
