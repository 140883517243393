import React, { useEffect, useState } from 'react';
import axios from 'axios';
import settingData from "../login/setting.json"
import "./style.css";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { Button, Grid, TextField, Typography, Select, MenuItem, CircularProgress, } from "@mui/material";
import { ErrorMessage, Formik } from "formik";
import moment from 'moment/moment';
import { BASE_URL } from '../../api/client';

const studentSchema = Yup.object().shape({
  profileName: Yup.string().required("* Please enter profile name"),
  rollNumber: Yup.string().required("* Please enter roll number"),
  coachingInstitute: Yup.string().required("* Please enter coaching institute"),
  yearOfPreparation: Yup.string().required("* Please enter year of preparation"),
  levelOfPreparation: Yup.string().required("* Please select level of preparation"),
  subjectsToBePrepared: Yup.string().required("* Please select subject to be prepared"),
  papersToBePrepared: Yup.string().required("* Please select paper to be prepared"),
});

const teacherAndAdminSchema = Yup.object().shape({
  profileName: Yup.string().required("* Please enter profile name"),
  coachingInstitute: Yup.string().required("* Please enter coaching institute"),
  yearOfPreparation: Yup.string().required("* Please enter year of preparation"),
  levelOfPreparation: Yup.string().required("* Please select level of preparation"),
  subjectsToBePrepared: Yup.string().required("* Please select subject to be prepared"),
  papersToBePrepared: Yup.string().required("* Please select paper to be prepared"),
  gradingSchemePaper1: Yup.string().required("* Please enter general studies grading scheme for paper 1"),
  gradingSchemePaper2: Yup.string().required("* Please enter general studies grading scheme for paper 2"),
  gradingSchemePaper3: Yup.string().required("* Please enter general studies grading scheme for paper 3"),
  gradingSchemePaper4: Yup.string().required("* Please enter general studies grading scheme for paper 4"),
  gradingSchemeAllPaper: Yup.string().required("* Please enter grading scheme guidelines for all papers"),
});

function UserSetting() {
  const [loading, setLoading] = useState(false)
  const [responseId, setResponseId] = useState()
  const [formData, setFormData] = useState()
  const [studentFormData, setStudentFormData] = useState()

  const teacherAndAdmIninitValues = {
    profileName: formData?.otherdata?.rubric_name || "",
    coachingInstitute: formData?.otherdata?.rubric_institute || "",
    yearOfPreparation: formData?.otherdata?.rubric_year || "",
    levelOfPreparation: formData?.otherdata?.rubric_level || "",
    subjectsToBePrepared: formData?.otherdata?.rubric_subjects || "",
    papersToBePrepared: formData?.otherdata?.rubric_papers || "",
    gradingSchemePaper1: formData?.otherdata?.rubric_1 || "",
    gradingSchemePaper2: formData?.otherdata?.rubric_2 || "",
    gradingSchemePaper3: formData?.otherdata?.rubric_3 || "",
    gradingSchemePaper4: formData?.otherdata?.rubric_4 || "",
    gradingSchemeAllPaper: formData?.otherdata?.rubric_G || "",
  };

  const studentInitValues = {
    profileName: studentFormData?.first_name + " " + studentFormData?.last_name || "",
    rollNumber: studentFormData?.roll_number || "",
    coachingInstitute: formData?.otherdata?.rubric_institute || "",
    yearOfPreparation: formData?.otherdata?.rubric_year || "",
    levelOfPreparation: formData?.otherdata?.rubric_level || "",
    subjectsToBePrepared: formData?.otherdata?.rubric_subjects || "",
    papersToBePrepared: formData?.otherdata?.rubric_papers || "",
  };

  const roleType = JSON.parse(localStorage.getItem('userDetails'))

  useEffect(() => {
    const currentDate = moment(new Date()).toISOString();
    if (currentDate > roleType?.expiry) {
      localStorage.removeItem("userDetails");
      toast.error("session expired");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    let payload = {
      typeofreq: roleType?.loginData?.role === "Student" ? "student" : roleType?.loginData?.role,
      id: roleType?.loginData?.id
    }
    axios
      .post(`${BASE_URL}/getrubric/`, payload)
      .then((res) => {
        const respo = res?.status === 200;
        if (respo) {
          setResponseId(roleType?.loginData?.role === "Student" ? res?.data?.student : res?.data?.teacher);
          setFormData(res?.data?.teachersdata);
          setStudentFormData(res?.data?.owndata);
          setLoading(false);
        }
        else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log('error in axios', err);
      });
  }, []);


  const handleSettingUpdate = (values) => {
    setLoading(true);
    let payload = {}
    payload = {
      typeofreq: "teacher",
      id: responseId,
      changejson: {
        rubric_1: values?.gradingSchemePaper1,
        rubric_2: values?.gradingSchemePaper2,
        rubric_3: values?.gradingSchemePaper3,
        rubric_4: values?.gradingSchemePaper4,
        rubric_G: values?.gradingSchemeAllPaper,
        rubric_dp: "None",
        rubric_name: values?.profileName,
        rubric_institute: values?.coachingInstitute,
        rubric_year: values?.yearOfPreparation,
        rubric_level: values?.levelOfPreparation,
        rubric_subjects: values?.subjectsToBePrepared,
        rubric_papers: values?.papersToBePrepared,
        uploaded_file: "None",
      }
    }

    axios
      .post(`${BASE_URL}/getrubric/`, payload)
      .then((res) => {
        const respo = res?.status === 200;
        if (respo) {
          setResponseId(roleType?.loginData?.role === "Student" ? res?.data?.student : res?.data?.teachersdata?.id);
          setFormData(res?.data?.teachersdata);
          setStudentFormData(res?.data?.owndata);
          setLoading(false);
          toast.success("Details saved successfully")
        }
        else {
          setLoading(false);
          toast.error("Details save failed");
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Details save failed");
        console.log('error in axios', err);
      });

  }
  return (
    <div style={{ padding: "40px 20px" }}>
      <Grid container>
        <Grid item lg={2} md={2} sm={0}></Grid>
        <Grid item lg={8} md={8} sm={12}>
          <Formik
            validationSchema={roleType?.loginData?.role === "Student" ? studentSchema : teacherAndAdminSchema}
            initialValues={roleType?.loginData?.role === "Student" ? studentInitValues : teacherAndAdmIninitValues}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(values) => handleSettingUpdate(values)}
            enableReinitialize
          >
            {(formik) => {
              const {
                handleChange,
                handleBlur,
                handleSubmit,
                values,
              } = formik;
              return (
                <form onSubmit={handleSubmit}>
                  <div className='field-warps'>
                    <Typography className='login-field-lables'>Profile Name:</Typography>
                    <TextField
                      id="profileName"
                      name="profileName"
                      placeholder="Enter profile name"
                      className="login-text-fields"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.profileName}
                      disabled={roleType?.loginData?.role === "Student"}
                    />
                    <ErrorMessage
                      name="profileName"
                      component="span"
                      className="error"
                    />
                  </div>
                  {roleType?.loginData?.role === "Student" &&
                    <div className='field-warps'>
                      <Typography className='login-field-lables'>Roll Number:</Typography>
                      <TextField
                        id="rollNumber"
                        name="rollNumber"
                        placeholder="Enter roll number"
                        className="login-text-fields"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.rollNumber}
                        disabled={roleType?.loginData?.role === "Student"}
                      />
                      <ErrorMessage
                        name="rollNumber"
                        component="span"
                        className="error"
                      />
                    </div>
                  }
                  <div className='field-warps'>
                    <Typography className='login-field-lables'>Coaching Institute:</Typography>
                    <TextField
                      id="coachingInstitute"
                      name="coachingInstitute"
                      placeholder="Enter coaching institute"
                      className="login-text-fields"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.coachingInstitute}
                      disabled={roleType?.loginData?.role === "Student"}
                    />
                    <ErrorMessage
                      name="coachingInstitute"
                      component="span"
                      className="error"
                    />
                  </div>
                  <div className='field-warps'>
                    <Typography className='login-field-lables'>Year Of Preparation:</Typography>
                    <TextField
                      type='number'
                      id="yearOfPreparation"
                      name="yearOfPreparation"
                      placeholder="Enter year of preparation"
                      className="login-text-fields"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.yearOfPreparation}
                      disabled={roleType?.loginData?.role === "Student"}
                    />
                    <ErrorMessage
                      name="yearOfPreparation"
                      component="span"
                      className="error"
                    />
                  </div>
                  <div className='field-warps'>
                    <Typography className='login-field-lables'>Level Of Preparation:</Typography>
                    <Select
                      id="levelOfPreparation"
                      name="levelOfPreparation"
                      placeholder="Select level of preparation"
                      className="login-text-fields"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.levelOfPreparation}
                      disabled={roleType?.loginData?.role === "Student"}
                      sx={{
                        "& .MuiSelect-select .notranslate::after":
                          "Select a filter"
                            ? { content: `"Select level of preparation"`, opacity: 1 }
                            : {},
                      }}
                    >
                      <MenuItem value="Prelims">Prelims</MenuItem>
                      <MenuItem value="Mains">Mains</MenuItem>
                    </Select>
                    <ErrorMessage
                      name="levelOfPreparation"
                      component="span"
                      className="error"
                    />
                  </div>
                  <div className='field-warps'>
                    <Typography className='login-field-lables'>Subjects To Be Prepared:</Typography>
                    <Select
                      id="subjectsToBePrepared"
                      name="subjectsToBePrepared"
                      placeholder="Select subjects to be prepared"
                      className="login-text-fields"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.subjectsToBePrepared}
                      disabled={roleType?.loginData?.role === "Student"}
                      sx={{
                        "& .MuiSelect-select .notranslate::after":
                          "Select a filter"
                            ? { content: `"Select subjects to be prepared"`, opacity: 1 }
                            : {},
                      }}
                    >
                      <MenuItem value="Current affairs of national and international importance">Current
                        affairs of national and international importance</MenuItem>
                      <MenuItem value="Environment, Ecology, and Biodiversity">Environment, Ecology, and
                        Biodiversity</MenuItem>
                      <MenuItem value="Ancient, Medieval and Modern History of India">Ancient, Medieval and Modern
                        History of India</MenuItem>
                      <MenuItem value="Art and Culture">Art and Culture</MenuItem>
                      <MenuItem value="Indian polity and governance">Indian polity and governance</MenuItem>
                      <MenuItem value="General science and technology">General science and technology</MenuItem>
                      <MenuItem value="Indian and world geography">Indian and world geography</MenuItem>
                      <MenuItem value="Economic and social development">Economic and social development</MenuItem>
                      <MenuItem value="Social Issues">Social Issues</MenuItem>
                    </Select>
                    <ErrorMessage
                      name="subjectsToBePrepared"
                      component="span"
                      className="error"
                    />
                  </div>
                  <div className='field-warps'>
                    <Typography className='login-field-lables'>Papers To Be Prepared:</Typography>
                    <Select
                      id="papersToBePrepared"
                      name="papersToBePrepared"
                      placeholder="Select papers to be prepared"
                      className="login-text-fields"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.papersToBePrepared}
                      disabled={roleType?.loginData?.role === "Student"}
                      sx={{
                        "& .MuiSelect-select .notranslate::after":
                          "Select a filter"
                            ? { content: `"Select papers to be prepared"`, opacity: 1 }
                            : {},
                      }}
                    >
                      <MenuItem value="Essay Paper">Essay Paper</MenuItem>
                      <MenuItem value="GS Paper 1">GS Paper 1</MenuItem>
                      <MenuItem value="GS Paper 2">GS Paper 2</MenuItem>
                      <MenuItem value="GS Paper 3">GS Paper 3</MenuItem>
                      <MenuItem value="GS Paper 4">GS Paper 4</MenuItem>
                      <MenuItem value="Indian Language Paper">Indian Language Paper</MenuItem>
                      <MenuItem value="English Language Paper">English Language Paper</MenuItem>
                    </Select>
                    <ErrorMessage
                      name="papersToBePrepared"
                      component="span"
                      className="error"
                    />
                  </div>
                  {roleType?.loginData?.role !== "Student" &&
                    <>
                      <div className='field-warps'>
                        <Typography className='login-field-lables'>General Studies Grading Scheme for Paper 1:</Typography>
                        <TextField
                          id="gradingSchemePaper1"
                          name="gradingSchemePaper1"
                          placeholder="Enter general studies grading scheme for paper 1"
                          className="login-text-fields"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.gradingSchemePaper1}
                          type='number'
                        />
                        <ErrorMessage
                          name="gradingSchemePaper1"
                          component="span"
                          className="error"
                        />
                      </div>
                      <div className='field-warps'>
                        <Typography className='login-field-lables'>General Studies Grading Scheme for Paper 2:</Typography>
                        <TextField
                          id="gradingSchemePaper2"
                          name="gradingSchemePaper2"
                          placeholder="Enter general studies grading scheme for paper 2"
                          className="login-text-fields"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.gradingSchemePaper2}
                          type='number'
                        />
                        <ErrorMessage
                          name="gradingSchemePaper2"
                          component="span"
                          className="error"
                        />
                      </div>
                      <div className='field-warps'>
                        <Typography className='login-field-lables'>General Studies Grading Scheme for Paper 3:</Typography>
                        <TextField
                          id="gradingSchemePaper3"
                          name="gradingSchemePaper3"
                          placeholder="Enter general studies grading scheme for paper 3"
                          className="login-text-fields"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.gradingSchemePaper3}
                          type='number'
                        />
                        <ErrorMessage
                          name="gradingSchemePaper3"
                          component="span"
                          className="error"
                        />
                      </div>
                      <div className='field-warps'>
                        <Typography className='login-field-lables'>General Studies Grading Scheme for Paper 4:</Typography>
                        <TextField
                          id="gradingSchemePaper4"
                          name="gradingSchemePaper4"
                          placeholder="Enter general studies grading scheme for paper 4"
                          className="login-text-fields"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.gradingSchemePaper4}
                          type='number'
                        />
                        <ErrorMessage
                          name="gradingSchemePaper4"
                          component="span"
                          className="error"
                        />
                      </div>
                      <div className='field-warps'>
                        <Typography className='login-field-lables'>Grading scheme guidelines for all papers:</Typography>
                        <TextField
                          id="gradingSchemeAllPaper"
                          name="gradingSchemeAllPaper"
                          placeholder="Enter Grading Scheme Guidelines For All Papers"
                          className="login-text-fields"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.gradingSchemeAllPaper}
                          type='number'
                        />
                        <ErrorMessage
                          name="gradingSchemeAllPaper"
                          component="span"
                          className="error"
                        />
                      </div>
                    </>
                  }
                  <div className='setting-save-btn'>
                    {roleType?.loginData?.role !== "Student" &&
                      <Button style={{ textTransform: "capitalize" }} type="submit" variant="contained" >
                        {loading ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Save"}
                      </Button>
                    }
                  </div>
                </form>
              )
            }}
          </Formik>
        </Grid>
        <Grid item lg={2} md={2} sm={0}></Grid>
      </Grid>
    </div>
  )
}

export default UserSetting