import React, { useState } from 'react'
import "../../login/style.css"
import axios from 'axios';
import { Button, Grid, TextField, Typography, InputAdornment, CircularProgress, } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import Logo from "../../../assets/images/logo.svg"
import SwipeableTextMobileStepper from '../../login/carouselPage';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import { BASE_URL } from '../../../api/client';

const LoginSchema = Yup.object().shape({
    username: Yup.string().required("* Please enter Username"),
    password: Yup.string().required("* Please enter password"),
});

function AdminLoginPage() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const initValues = {
        username: "",
        password: "",
    };

    const handleAdminLogin = (values) => {
        setLoading(true);
        const { username, password } = values;
        axios
            .get(`${BASE_URL}/adminlogin/?username=${username}&password=${password}`)
            .then((res) => {
                const respo = res?.status === 200;
                if (respo) {
                    localStorage.setItem("userDetails", JSON.stringify({ loginData: res?.data?.teacher, expiry: moment().add(4, "hours").toISOString() }));
                    setLoading(false);
                    navigate("/admin/teachers")
                    toast.success("Logged in successfully");
                }
                else {
                    toast.error("Login failed. Please check your credentials");
                    setLoading(false);
                }
            })
            .catch((err) => {
                toast.error("Login failed. Please check your credentials");
                setLoading(false);
                console.log('error in axios', err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="page-background">
            <Grid container>
                <Grid item md={7} lg={7} sm={12}>
                    <SwipeableTextMobileStepper />
                </Grid>
                <Grid item md={5} lg={5} sm={12}>
                    <Grid container className='login-form-parent-div'>
                        <Grid item md={3} lg={3} sm={3}></Grid>
                        <Grid item md={6} lg={6} sm={6}>
                            <div className='form-wraps'>
                                <div style={{ margin: "0% 0% 20% 0%" }}>
                                    <img src={Logo} alt='Logo' />
                                </div>
                                <div className='teacher-login-lable'>Admin Login</div>
                                <Formik
                                    validationSchema={LoginSchema}
                                    initialValues={initValues}
                                    validateOnBlur={false}
                                    validateOnChange={false}
                                    onSubmit={(values) => handleAdminLogin(values)}
                                >
                                    {(formik) => {
                                        const {
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            values,
                                        } = formik;
                                        return (
                                            <form onSubmit={handleSubmit}>
                                                <div className='field-warps'>
                                                    <Typography className='login-field-lables'>Username</Typography>
                                                    <TextField
                                                        id="username"
                                                        name="username"
                                                        placeholder="Enter username"
                                                        className="login-text-fields"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.username}
                                                    />
                                                    <ErrorMessage
                                                        name="username"
                                                        component="span"
                                                        className="error"
                                                    />
                                                </div>
                                                <div className='field-warps'>
                                                    <Typography className='login-field-lables'>Password</Typography>
                                                    <TextField
                                                        id="password"
                                                        name="password"
                                                        placeholder="Enter password"
                                                        className="login-text-fields"
                                                        type={showPassword ? "text" : "password"}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment
                                                                    position="start"
                                                                    sx={{ cursor: "pointer" }}
                                                                    onClick={() => setShowPassword(!showPassword)}
                                                                >
                                                                    {showPassword ? (
                                                                        <VisibilityIcon />
                                                                    ) : (
                                                                        <VisibilityOffIcon />
                                                                    )}
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.password}
                                                    />
                                                    <ErrorMessage
                                                        name="password"
                                                        component="span"
                                                        className="error"
                                                    />
                                                </div>
                                                <div className='field-warps'>
                                                    <Button type="submit" className='login-button'>
                                                        {loading ? (
                                                            <CircularProgress size={24} sx={{ color: "#fff" }} />
                                                        ) : (
                                                            "Log In"
                                                        )}
                                                    </Button>
                                                </div>
                                            </form>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </Grid>
                        <Grid item md={3} lg={3} sm={3}></Grid>
                    </Grid>
                </Grid>
            </Grid>

        </div>
    )
}

export default AdminLoginPage