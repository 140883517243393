import React from 'react'
import UserSetting from "./userSetting";
import AdminSetting from './adminSetting';

function SettingPage() {
  const roleType = JSON.parse(localStorage.getItem('userDetails'))

  return (
    <div>
        {roleType?.loginData?.role === "admin" ? <AdminSetting/> : <UserSetting/>}
    </div>
  )
}

export default SettingPage