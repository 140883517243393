import React, { useState, useEffect } from 'react'
import "./style.css"
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Grid, InputAdornment, Switch, TextField } from '@mui/material';
import { TablePagination } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from '../../../api/client';
import { toast } from 'react-toastify';
import moment from 'moment/moment';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: 12
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


function AdminTeacher() {

    const navigate = useNavigate();
    const [teacherData, setTeacherData] = useState()
    console.log("teacherData", teacherData)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState();
    const [statusLable, setStatusLable] = useState();
    const [teacherID, setTeacherID] = useState()
    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const roleType = JSON.parse(localStorage.getItem('userDetails'))

    useEffect(() => {
        const currentDate = moment(new Date()).toISOString();
        if (currentDate > roleType?.expiry) {
            localStorage.removeItem("userDetails");
            toast.error("session expired");
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
    }, []);


    const TeachersList = () => {
        setLoading(true);
        axios
            .get(`${BASE_URL}/seeteachers/`)
            .then((res) => {
                const respo = res?.status === 200;
                if (respo) {
                    setTeacherData(res?.data?.teachers);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log('error in axios', err);
            });
    }

    useEffect(() => {
        TeachersList()
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleAddTeacher = () => {
        navigate("/add-teacher");
    };

    const handleStatusChange = (e, row) => {
        setTeacherID(row)
        setStatusLable(e.target.checked ? "activate" : "deactivate");
        setStatusMessage(e.target.checked ? "activated" : "deactivated");
        setOpenDeleteConfirmDialog(true);
    }

    const handleDeleteConfirmClose = () => {
        setOpenDeleteConfirmDialog(false);
    };

    const handleTeacherDelete = () => {
        let payload = {
            id: teacherID.id,
            validity: !teacherID.validity
        }
        setOpenDeleteConfirmDialog(false);
        setDeleteLoading(true);
        console.log("payload", payload)
        axios
            .post(`${BASE_URL}/modifyteacher/`, payload)
            .then((res) => {
                const respo = res?.status === 200;
                if (respo) {
                    console.log("res?.data", res?.data)
                    setDeleteLoading(false);
                    toast.success(`Teacher ${statusMessage} successfully`);
                    TeachersList();
                }
                else {
                    setDeleteLoading(false);
                    toast.error(`Teacher ${statusMessage} failed`);
                }
            })
            .catch((err) => {
                setDeleteLoading(false);
                toast.error(`Teacher ${statusMessage} failed`);
                console.log('error in axios', err);
            });
    }

    const handleSearch = (event) => {
        setSearchQuery(event.target.value.trim());
        setPage(0);
    };

    const searchTeacherData = searchQuery !== '' ?
        teacherData?.filter((row) =>
            String(row?.id).toLowerCase().includes(searchQuery.toLowerCase()) ||
            row?.real_name.toLowerCase().includes(searchQuery.toLowerCase())
        ) : teacherData;

    return (

        <div style={{ padding: "40px 20px" }}>
            <Grid container spacing={2}>
                <Grid item sx={12} md={6} lg={6}>
                    <div><h5 className='page-name'>Teachers</h5></div>
                </Grid>
                <Grid item sx={12} md={4} lg={4}>
                    <div className="studnet-search-div">
                        <TextField
                            className='search-field'
                            name="regNumber"
                            placeholder={"Search"}
                            type={"text"}
                            onChange={(e) => handleSearch(e)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="start"
                                        sx={{ cursor: "pointer", marginLeft: "0px" }}
                                    >
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                </Grid>
                <Grid item sx={12} md={2} lg={2}>
                    <div>
                        <Button variant="contained" onClick={handleAddTeacher} className='add-new-student-btn'>Add Teacher</Button>
                    </div>
                </Grid>
            </Grid>
            {loading ?
                <div className='loder-div-styles'>
                    <CircularProgress size={50} />
                </div>
                :
                <>
                    <TableContainer component={Paper} style={{ marginTop: "25px" }}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell align="center" className='teacher-TH-cell1'>S. NO.</StyledTableCell>
                                    <StyledTableCell align="center" className='teacher-TH-cell2'>TEACHER ID</StyledTableCell>
                                    <StyledTableCell className='teacher-TH-cell3'>NAME</StyledTableCell>
                                    <StyledTableCell align="center" className='teacher-TH-cell2'>ACTION</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {searchTeacherData && searchTeacherData?.length > 0 ?
                                    searchTeacherData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        ?.map((row, index) => (
                                            row.role === "admin" ? "" :
                                                <StyledTableRow key={page * rowsPerPage + index}>
                                                    <StyledTableCell align="center">{page * rowsPerPage + index + 1}</StyledTableCell>
                                                    <StyledTableCell align="center">{row?.id}</StyledTableCell>
                                                    <StyledTableCell>{row?.real_name}</StyledTableCell>
                                                    <StyledTableCell align="center"> <Switch id='report-toggle-switch' name="validity" onChange={(e) => handleStatusChange(e, row)} checked={row?.validity} /></StyledTableCell>
                                                </StyledTableRow>
                                        ))
                                    :
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={6} align="center">No Data Found</StyledTableCell>
                                    </StyledTableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {searchTeacherData?.length > 10 &&
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 50]}
                            component="div"
                            count={searchTeacherData?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />}
                </>}
            <Dialog
                open={openDeleteConfirmDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to {statusLable}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='agree-disagree-btn-div'>
                    <Button variant="contained" disabled={deleteLoading} onClick={handleDeleteConfirmClose} className="disagree-btn">Cancel</Button>
                    <Button variant="contained" onClick={handleTeacherDelete} style={{ textTransform: "capitalize" }}>
                        {deleteLoading ? (
                            <CircularProgress size={24} sx={{ color: "#fff" }} />
                        ) : (
                            statusLable
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AdminTeacher