import React, { useState } from 'react'
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { ErrorMessage, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BASE_URL } from '../../api/client';
import axios from 'axios';

const addStudentSchema = Yup.object().shape({
    firstName: Yup.string().required("* Please enter first name"),
    lastName: Yup.string().required("* Please enter last name"),
    rollNumber: Yup.string().required("* Please enter roll number"),
});

function AddStudent() {
    const navigate = useNavigate();
    const addStudentInitValues = {
        firstName: "",
        lastName: "",
        rollNumber: "",
    };

    const roleType = JSON.parse(localStorage.getItem('userDetails'));
    const [loading, setLoading] = useState(false)

    const handleStudnetAddSubmit = (values) => {

        let payload = {
            students: [
                {
                    teacher: roleType?.loginData?.id,
                    first_name: values.firstName,
                    last_name: values.lastName,
                    roll_number: values.rollNumber
                }
            ]
        }
        setLoading(true);
        axios
            .post(`${BASE_URL}/addnewstudents/`, payload)
            .then((res) => {
                const respo = res?.status === 200;
                if (respo) {
                    setLoading(false);
                    toast.success("Student added successfully");
                    navigate("/students");
                }
                else {
                    setLoading(false);
                    toast.error("Student add failed");
                }
            })
            .catch((err) => {
                setLoading(false);
                toast.error("Student add failed");
                console.log('error in axios', err);
            });

    }

    const handleBackToStudnet = () => {
        navigate("/students");
    }

    return (
        <Grid container>
            <Grid item md={12} lg={12} sm={12}>
                <div style={{ margin: "3%" }}>
                    <div className='add-student-back-btn-div'>
                        <Button variant="outlined" onClick={handleBackToStudnet} className='student-back-btn'><ArrowBackIcon /> Back</Button>
                    </div>
                </div>
                <div className="add-student-pratent-div">
                    <div className='custom-box-style'>
                        <Formik
                            validationSchema={addStudentSchema}
                            initialValues={addStudentInitValues}
                            validateOnBlur={false}
                            validateOnChange={false}
                            onSubmit={(values) => handleStudnetAddSubmit(values)}
                        >
                            {(formik) => {
                                const {
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    values,
                                } = formik;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <div className='field-warps'>
                                            <Typography className='login-field-lables'>First Name</Typography>
                                            <TextField
                                                id="firstName"
                                                name="firstName"
                                                placeholder="Enter first name"
                                                className="login-text-fields"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.firstName}
                                            />
                                            <ErrorMessage
                                                name="firstName"
                                                component="span"
                                                className="error"
                                            />
                                        </div>
                                        <div className='field-warps'>
                                            <Typography className='login-field-lables'>Last Name</Typography>
                                            <TextField
                                                id="lastName"
                                                name="lastName"
                                                placeholder="Enter last name"
                                                className="login-text-fields"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.lastName}
                                            />
                                            <ErrorMessage
                                                name="lastName"
                                                component="span"
                                                className="error"
                                            />
                                        </div>
                                        <div className='field-warps'>
                                            <Typography className='login-field-lables'>Roll Number</Typography>
                                            <TextField
                                                id="rollNumber"
                                                name="rollNumber"
                                                placeholder="Enter roll number"
                                                className="login-text-fields"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.rollNumber}
                                                type="number"
                                            />
                                            <ErrorMessage
                                                name="rollNumber"
                                                component="span"
                                                className="error"
                                            />
                                        </div>
                                        <div className='field-warps'>
                                            <Button className='login-button' type="submit">
                                                {loading ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : " Add student"}
                                            </Button>
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default AddStudent